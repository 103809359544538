import { guid } from 'src/app/common/utils/guid';
import { VisitorService } from './../../services/visitor.service';
import { SocialLoginComponent } from './../social.login/social.login.component';
import {
  isEmptyArray,
  upperFirstLetter,
} from 'src/app/common/utils/object.extensions';
import { BaseComponent } from 'src/app/common/components/base.component';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { VisitorEvent } from '../../models/visitor.event';
import { ActivatedRoute } from '@angular/router';
import {
  UserTrip,
  TripStorageCommand,
  UserTripModel,
  CreateTripCommand,
} from '../../models/vititor.trip.storage.command';
import { ConfigService } from 'src/app/common/services/config/config.service';
import { switchMap, timer, takeUntil, share, tap } from 'rxjs';
import { VisitorPlace } from '../../models/visitor.place';
import { EventTimeService } from '../../../event-calendar/services/event.time.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ig-add-to-trip',
  templateUrl: './add.to.trip.component.html',
  styleUrls: ['./add.to.trip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddToTripComponent
  extends BaseComponent
  implements AfterViewInit, OnChanges
{
  public isInAnyTrip = false;
  public isAddingNewTrip = false;
  public isLoadingTrips = false;
  public trips: UserTripModel[] = [];
  public newTripTitle = '';
  public tripFormGroup: FormGroup;
  public isTripDetailsMode = false;
  public isMenuOpened = false;

  @Input() public item: VisitorEvent | VisitorPlace;
  @Input() public showItemType = true;
  @Input() public isNeedLogin = true;
  @Input() public forTripPlannerV3 = false;
  @Input() public highlightBorderTop = false;
  @Input() public mode: 'normal' | 'half' = 'normal';

  snackBar = inject(MatSnackBar);

  @Input() set userTrips(trips: UserTrip[] | UserTripModel[] | null) {
    if (!trips) {
      this.trips = null;
      return;
    }
    // Convert UserTrip[] to UserTripModel[] by adding required properties if they don't exist
    this._trips = trips.map((trip) => {
      if ('isShowingDetails' in trip && 'isSuccess' in trip) {
        return trip as UserTripModel;
      }
      return {
        ...trip,
        isShowingDetails: false,
        isSuccess: false,
      } as UserTripModel;
    });
  }
  get userTrips(): UserTripModel[] | null {
    return this._trips;
  }
  private _trips: UserTripModel[] | null = null;

  @Output() loginChanged = new EventEmitter<boolean>();

  @ViewChild('menuTrigger', { static: true })
  public menuTrigger: MatMenuTrigger;
  @ViewChild('logindiv', { static: false })
  public logindiv: ElementRef;
  @ViewChild('deleteMenuTrigger', { static: false })
  public deleteMenuTrigger: MatMenuTrigger;
  @ViewChildren('buttons')
  public buttons: QueryList<ElementRef>;
  @ViewChild('socialLogin', { static: false })
  socialLogin: SocialLoginComponent;

  constructor(
    public activatedRoute: ActivatedRoute,
    public visitorService: VisitorService,
    public timeService: EventTimeService,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef
  ) {
    super({ activatedRoute });

    this.tripFormGroup = this.formBuilder.group({
      tripTitle: [null, [Validators.required]],
    });
  }

  async ngAfterViewInit() {
    this.trips = this.userTrips || (await this.loadUserTripsFromDB());
    this.checkIsInAnyTrip();
  }

  public get isUserLoggedIn() {
    return SocialLoginComponent.isUserLoggedIn;
  }

  public async loginStatusChanged(status: boolean) {
    // for v3, the user trips are loaded in trip.planner.component, so here is to notify it to reload user trips and assign values to this.trips in ngOnChanges
    if (this.forTripPlannerV3) {
      this.loginChanged.emit(status);
    }
    console.log('loginStatusChanged in add to trip', this.userTrips);
    this.trips = this.userTrips || (await this.loadUserTripsFromDB());
    this.checkIsInAnyTrip();
    this.ref.detectChanges();
  }

  public get inTripText() {
    if (this.mode === 'half') {
      return '- MY TRIP';
    }
    return this.showItemType
      ? `This ${upperFirstLetter(this.itemType())} is in My Trip`
      : 'In my trip';
  }

  public get addToTripText() {
    if (this.mode === 'half') {
      return '+ MY TRIP';
    }
    return this.showItemType
      ? `Add This ${upperFirstLetter(this.itemType())} to My Trip`
      : 'Add to my trip';
  }

  public get isOverLimit() {
    return this.trips?.length >= ConfigService.config.tripPlanner.maxTripCount;
  }

  public async openTripMenu() {
    this.trips = this.userTrips || (await this.loadUserTripsFromDB());
    this.checkIsInAnyTrip();
    this.menuTrigger.openMenu();
    this.isMenuOpened = true;
    this.tripFormGroup.get('tripTitle')?.markAsUntouched();

    // initialize google login again, otherwise only the first google login button can be shown on the page
    setTimeout(() => {
      this.socialLogin?.initGoogleLogin();
    });
  }

  public onClose() {
    this.trips.forEach((x) => {
      x.isSuccess = false;
    });
  }

  public tripTitleWithStops(trip: UserTrip) {
    if (this.forTripPlannerV3) {
      let total = 0;
      for (const day of trip.days) {
        total += day.attractions.length;
      }
      return `${trip.title} (${trip.days.length} day${
        trip.days.length > 1 ? 's' : ''
      }, ${total} stop${total > 1 ? 's' : ''})`;
    }

    return `${trip.title} (${trip.attractions.length} stop${
      trip.attractions.length > 1 ? 's' : ''
    })`;
  }

  public tripTitleInputFocus($event) {
    $event.stopPropagation();
  }

  public addNewTrip() {
    this.isAddingNewTrip = true;
  }

  public saveNewTrip() {
    if (this.tripFormGroup.invalid) return;

    this.createNewTrip(this.tripFormGroup.get('tripTitle').value);
    this.tripFormGroup.get('tripTitle').setValue('');
    this.isAddingNewTrip = false;
    this.scrollToTrip(this.trips.length - 1);
  }

  public createNewTrip(title: string) {
    const newDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

    const item = {
      title,
      userFacebookId: SocialLoginComponent.userId,
      hubName: this.hubName,
      attractions: [],
      days: [
        {
          date: newDate,
          dateText: this.timeService.getOnlyDisplayDate(newDate),
          note: '',
          attractions: [],
        },
      ],
    } as UserTripModel;

    if (!this.trips) {
      this.trips = [item];
    } else {
      this.trips = [...this.trips, item];
    }

    // don't know why change the trips not trigger the view change (for a new Google user, create the first trip), need to force it
    this.logindiv?.nativeElement?.click();

    if (SocialLoginComponent.isUserLoggedIn) {
      const command = {
        id: guid(),
        userFacebookId: SocialLoginComponent.userId,
        newTrip: {
          title,
          userFacebookId: SocialLoginComponent.userId,
          hubName: this.hubName,
        } as UserTrip,
      } as CreateTripCommand;

      this.visitorService
        .createTrip(this.hubName, command)
        .pipe(
          takeUntil(this.isDestroyed),
          tap(async () => {
            this.tripFormGroup.get('tripTitle')?.markAsUntouched();
            this.trips = await this.loadUserTripsFromDB();
          })
        )
        .subscribe();
    }
  }

  public isInTrip(tripIndex: number) {
    if (this.forTripPlannerV3) {
      return this.isInTripV3(tripIndex);
    }

    return this.trips[tripIndex].attractions.some(
      (x) => x.feedId === this.item._id
    );
  }

  public isInTripV3(tripIndex: number) {
    let isIn = false;
    for (const day of this.trips[tripIndex].days) {
      if (day.attractions.some((x) => x.feedId === this.item._id)) {
        isIn = true;
      }
    }

    return isIn;
  }

  public checkIsInAnyTrip() {
    if (this.forTripPlannerV3) {
      this.checkIsInAnyTripV3();
      return;
    }

    if (!this.trips || isEmptyArray(this.trips)) {
      this.isInAnyTrip = false;
      return;
    }
    this.isInAnyTrip = false;
    this.trips.forEach((trip) => {
      if (trip.attractions.any((x) => x.feedId === this.item._id)) {
        this.isInAnyTrip = true;
      }
    });
  }

  public checkIsInAnyTripV3() {
    if (!this.trips || isEmptyArray(this.trips)) {
      this.isInAnyTrip = false;
      return;
    }
    let isInAnyTrip = false;
    this.trips.forEach((trip) => {
      trip.days.forEach((day) => {
        if (day.attractions.any((x) => x.feedId === this.item._id)) {
          isInAnyTrip = true;
        }
      });
    });
    this.isInAnyTrip = isInAnyTrip;
  }

  public addOrRemoveFromTrip($event, tripIndex) {
    $event.stopPropagation && $event.stopPropagation();

    let notReachLimit = true;
    if (this.isInTrip(tripIndex)) {
      this.removeFromTrip(tripIndex);
    } else {
      notReachLimit = this.addItemToTrip(tripIndex);
    }

    if (notReachLimit) {
      this.checkIsInAnyTrip();
      this.showSuccess(tripIndex);
      this.scrollToTrip(tripIndex);
    }
  }

  public addItemToTrip(tripIndex: number): boolean {
    if (this.forTripPlannerV3) {
      if (!this.trips[tripIndex].days || !this.trips[tripIndex].days.length) {
        const newDate = new Date(
          new Date().getTime() + 7 * 24 * 60 * 60 * 1000
        );
        this.trips[tripIndex].days = [
          {
            date: newDate,
            dateText: this.timeService.getOnlyDisplayDate(newDate),
            note: '',
            attractions: [],
          },
        ];
      }

      if (this.trips[tripIndex].days[0].attractions.length >= 20) {
        this.snackBar.open(
          'You have reached the maximum number of attractions for that day!',
          'Close',
          {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: 'ig-error-snackbar',
          }
        );
        return false;
      }

      this.trips[tripIndex].days[0].attractions.push({
        feedType: this.itemType(),
        feedId: this.item._id,
        order: this.trips[tripIndex].days[0].attractions.length,
        item: this.item,
      });

      if (SocialLoginComponent.isUserLoggedIn) {
        this.visitorService
          .addAttractionToTrip(this.hubName, {
            userFacebookId: SocialLoginComponent.userId,
            tripId: this.trips[tripIndex].id,
            attraction: {
              feedType: this.itemType(),
              feedId: this.item._id,
              order: this.trips[tripIndex].days[0].attractions.length,
            },
          })
          .subscribe();
      }

      return true;
    }

    this.trips[tripIndex].attractions.push({
      feedType: this.itemType(),
      feedId: this.item._id,
      order: this.trips[tripIndex].attractions.length,
      item: this.item,
    });

    if (SocialLoginComponent.isUserLoggedIn) {
      this.visitorService
        .addAttractionToTrip(this.hubName, {
          userFacebookId: SocialLoginComponent.userId,
          tripId: this.trips[tripIndex].id,
          attraction: {
            feedType: this.itemType(),
            feedId: this.item._id,
            order: this.trips[tripIndex].attractions.length,
          },
        })
        .subscribe();
    }
    // this.addToLocalStorage(item, tripIndex);

    return true;
  }

  public removeFromTrip(tripIndex: number) {
    if (this.forTripPlannerV3) {
      for (const day of this.trips[tripIndex].days) {
        const index = day.attractions.getElementIndex(
          (x) => x.feedId === this.item._id
        );
        day.attractions.splice(index, 1);
      }
      if (SocialLoginComponent.isUserLoggedIn) {
        this.removeTripItemFromDB(tripIndex);
      }
      return;
    }

    if (this.trips[tripIndex] && this.trips[tripIndex].attractions.length) {
      let index = this.trips[tripIndex].attractions.getElementIndex(
        (x) => x.feedId === this.item._id
      );
      this.trips[tripIndex].attractions.splice(index, 1);
    }
    if (SocialLoginComponent.isUserLoggedIn) {
      this.removeTripItemFromDB(tripIndex);
    }
    // this.removeFromLocalStorage(tripIndex);
  }

  public deleteTrip(index: number) {
    if (SocialLoginComponent.userId) {
      this.visitorService
        .deleteTrip(
          this.hubName,
          SocialLoginComponent.userId,
          this.trips[index].id
        )
        .subscribe((data) => {
          this.trips = data.trips;
          this.deleteMenuTrigger?.closeMenu();
          this.tripFormGroup.get('tripTitle')?.markAsUntouched();
        });
    }

    this.trips.splice(index, 1);
    // this.updateTripInLS();
    this.checkIsInAnyTrip();
    // // if all trips are deleted, back to the create trip form
    this.tripFormGroup.get('tripTitle')?.markAsUntouched();
  }

  private removeTripItemFromDB(tripIndex) {
    if (this.forTripPlannerV3) {
      this.visitorService
        .removeAttractionFromTripV3(this.hubName, {
          feedId: this.item._id,
          userFacebookId: SocialLoginComponent.userId,
          tripIndex,
        })
        .subscribe();
      return;
    }

    this.visitorService
      .removeAttractionFromTrip(this.hubName, {
        feedId: this.item._id,
        userFacebookId: SocialLoginComponent.userId,
        tripIndex,
      })
      .subscribe();
  }

  private async loadUserTripsFromDB(): Promise<UserTripModel[]> {
    // let key = this.getLSKeyForTrip();
    // let trips = JSON.parse(localStorage.getItem(key)) as TripStorageCommand;
    // if (!trips || isEmptyArray(trips.trips)) {
    //   trips = new TripStorageCommand();
    // }
    // return trips.trips;

    if (!SocialLoginComponent.userId) return [];

    this.isLoadingTrips = true;
    const trips = await this.visitorService.getUserTrips(
      this.hubName,
      SocialLoginComponent.userId
    );
    console.log('trips', trips);

    // const key = this.getLSKeyForTrip();
    // localStorage.setItem(key, JSON.stringify(trips));

    this.isLoadingTrips = false;
    return trips ? trips.trips || [] : [];
  }

  private async loadUserTripsFromLocalStorage() {
    const key = this.getLSKeyForTrip();
    let trip = JSON.parse(localStorage.getItem(key)) as TripStorageCommand;
    if (!trip || isEmptyArray(trip.trips)) {
      trip = new TripStorageCommand();
    }
    return trip;
  }

  // public removeFromLocalStorage(id: string, tripIndex?: number) {
  //   let key = this.getLSKeyForTrip();
  //   // let keyWithUserID = LocalStorageService.getLocalStorageKeyForVisitorTrip(this.hubName, SocialLoginPopupComponent.userId);
  //   let trip = JSON.parse(localStorage.getItem(key)) as TripStorageCommand;

  //   if (!trip || !trip.trips || trip.trips.length <= tripIndex) {
  //     return;
  //   }

  //   if (trip.trips[tripIndex] && trip.trips[tripIndex].attractions.length) {
  //     let index = trip.trips[tripIndex].attractions.getElementIndex(
  //       (x) => x.feedId === id
  //     );
  //     trip.trips[tripIndex].attractions.splice(index, 1);
  //   }
  //   trip.trips[tripIndex].attractions =
  //     trip.trips[tripIndex].attractions.removeDuplicate();
  //   trip.timestamp = new Date();
  //   localStorage.setItem(key, JSON.stringify(trip));
  //   // localStorage.setItem(keyWithUserID, JSON.stringify(trip));

  //   this.trips = trip.trips;
  // }

  // private async addNewTripToLocalStorage(
  //   title: string,
  //   item?: VisitorEvent,
  //   type?: string
  // ): Promise<TripStorageCommand> {
  //   const key = this.getLSKeyForTrip();
  //   let userTrip = JSON.parse(localStorage.getItem(key)) as TripStorageCommand;
  //   if (!userTrip || isEmptyArray(userTrip.trips)) {
  //     userTrip = new TripStorageCommand();
  //   } else {
  //     if (
  //       userTrip.trips.length >= ConfigService.config.tripPlanner.maxTripCount
  //     ) {
  //       return userTrip;
  //     }
  //   }

  //   const newTrip = this.createTrip(title, item, type);
  //   userTrip.trips.push(newTrip);

  //   localStorage.setItem(key, JSON.stringify(userTrip));
  //   // let tripsWithUserId = clone(userTrip);
  //   // tripsWithUserId.userFacebookId = SocialLoginPopupComponent.userId || null;
  //   // localStorage.setItem(keyWithUserID, JSON.stringify(tripsWithUserId));

  //   return userTrip;
  // }

  // private updateTripInLS() {
  //   let key = this.getLSKeyForTrip();
  //   let trip = JSON.parse(localStorage.getItem(key)) as TripStorageCommand;
  //   // let keyWithUserID = LocalStorageService.getLocalStorageKeyForVisitorTrip(this.hubName, SocialLoginPopupComponent.userId);
  //   trip.trips = this.trips;
  //   localStorage.setItem(key, JSON.stringify(trip));
  //   // localStorage.setItem(keyWithUserID, JSON.stringify(trip));
  // }

  // private createTrip(
  //   title: string,
  //   item?: VisitorEvent,
  //   type?: string
  // ): UserTripModel {
  //   let newTrip = new UserTripModel();
  //   newTrip.attractions = [];
  //   if (item) {
  //     newTrip.attractions.push({
  //       feedType: type,
  //       feedId: item._id,
  //       order: 1,
  //     });
  //   }
  //   newTrip.title = title;
  //   newTrip.timestamp = new Date();
  //   newTrip.hubName = this.hubName;
  //   return newTrip;
  // }

  // private addToLocalStorage(item: VisitorEvent, tripIndex?: number) {
  //   let key = this.getLSKeyForTrip();
  //   // let keyWithUserID = LocalStorageService.getLocalStorageKeyForVisitorTrip(this.hubName, SocialLoginPopupComponent.userId);
  //   let trip = JSON.parse(localStorage.getItem(key)) as TripStorageCommand;
  //   if (!trip || !trip.trips) {
  //     return;
  //   }

  //   if (tripIndex >= trip.trips.length) {
  //     return;
  //   }

  //   // trip.trips[tripIndex].attractions = trip.trips[tripIndex].attractions.removeDuplicate();

  //   trip.trips[tripIndex].attractions.push({
  //     feedType: this.itemType(),
  //     feedId: item._id,
  //     order: trip.trips[tripIndex].attractions.length,
  //   });
  //   trip.trips[tripIndex].attractions =
  //     trip.trips[tripIndex].attractions.removeDuplicate();
  //   trip.trips[tripIndex].timestamp = new Date();
  //   trip.trips[tripIndex].hubName = this.hubName;
  //   localStorage.setItem(key, JSON.stringify(trip));
  //   // trip.userFacebookId = SocialLoginPopupComponent.userId || null;
  //   // localStorage.setItem(keyWithUserID, JSON.stringify(trip));
  // }

  private showSuccess(tripIndex: number) {
    this.trips[tripIndex].isShowingDetails = false;
    this.trips[tripIndex].isSuccess = true;
    setTimeout(() => {
      this.trips[tripIndex].isSuccess = false;
    }, 4000);
  }

  private scrollToTrip(tripIndex: number) {
    if (Array.from(this.buttons)[tripIndex]) {
      setTimeout(() => {
        this.scrollIntoView(Array.from(this.buttons)[tripIndex]['_elementRef']);
      }, 100);
    }
  }

  private scrollIntoView(element: ElementRef) {
    element.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  }

  private getLSKeyForTrip(): string {
    return SocialLoginComponent.isUserLoggedIn
      ? 'visitorTrip_' +
          this.hubName +
          '_' +
          SocialLoginComponent.userId +
          '_v2'
      : `visitorTrip_${this.hubName}_v3`;
  }

  private itemType(): 'event' | 'place' {
    return this.item.startTime ? 'event' : 'place';
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log('ngOnChanges');
    this.trips = this.userTrips || (await this.loadUserTripsFromDB());
    this.checkIsInAnyTrip();
  }
}
